<template>
  <section class="intro">
    <div class="intro-wrapper">
      <div class="intro-card">
        <div class="intro-card__title">Roobee marketplace</div>
        <div class="intro-card__image">
          <img src="@/assets/svg/calltoaction/card-1.svg" alt="#">
        </div>
        <div class="intro-card__text">
          Build investment portfolios
          using Crypto, ETFs, Pre-IPOs
          and many more.
        </div>
        <a href="https://app.roobee.io/sign_up" target="_blank" class="intro-card__button">
          Create portfolio
        </a>
      </div>
      <div class="intro-roobeecoin">
        <img src="@/assets/svg/calltoaction/RoobeeBigCoin.svg" alt="#">
      </div>
      <div class="intro-card">
        <img class="intro-card-try" src="@/assets/svg/calltoaction/TryIcon.svg" alt="#">
        <div class="intro-card__title">Roobee.Finance</div>
        <div class="intro-card__image">
          <img src="@/assets/svg/calltoaction/card-2.svg" alt="#">
        </div>
        <div class=" intro-card__text intro-card__text--green">
          Your companion in the world of DeFi
        </div>
        <div class="intro-card__text intro-card__text--small">
          Track, trade & invest on Ethereum,  BSC and the others
        </div>
        <a href="https://roobee.finance/" target="_self" class="intro-card__button intro-card__button--green">
          Explore it!
        </a>
      </div>
    </div>
    <div class="index-marketplace">
      <div class="index-marketplace__container container">
        <div class="index-marketplace__container-inner" />
        <div class="index-marketplace__fullscreen" v-swiper:mySwiperFullscreen="swiperFullscreenOption">
          <div class="swiper-wrapper">
            <div class="swiper-slide index-marketplace__fullscreen-screen" v-for="(item, i) in marketplaceInfo" :key="i" />
          </div>
        </div>
        <div class="index-marketplace__website-wrapper">
          <div class="website">
            <div class="top">
              <div class="buttons">
                <div class="item"></div>
                <div class="item"></div>
                <div class="item"></div>
              </div>
              <div class="search-area">
                <span class="url">roobee.io</span>
              </div>
            </div>
            <div class="content">
              <div v-swiper:mySwiperWebsite="swiperWebsiteOption">
                <div class="swiper-wrapper">
                  <div class="swiper-slide slide" v-for="(item, i) in marketplaceInfo" :key="i">
                    <img :src="`/gifs/${item.content}.gif`" :alt="$t(`index.marketplace.titles.${item.title}`)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="index-marketplace__info-wrapper">
          <div v-swiper:mySwiperInfo="swiperInfoOption">
            <div class="swiper-wrapper">
              <div class="swiper-slide index-marketplace__info-slide" v-for="(item, i) in marketplaceInfo" :key="i">
                <span class="pretitle" v-t="`index.marketplace.pre-titles.${item.title}`"/>
                <h3 class="title" v-t="`index.marketplace.titles.${item.title}`"/>
                <ul class="list">
                  <li v-t="`index.marketplace.list.${listItem}`" v-for="(listItem, i) in item.list" :key="i"/>
                </ul>
              </div>
            </div>
            <div class="swiper-scrollbar index-marketplace__info-scrollbar"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CallToAction",

  props: ['marketplaceInfo'],

  data() {
    return {
      swiperWebsiteOption: {
        slidesPerView: 1,
        direction: 'vertical',
        speed: 750,
        init: false,
        allowTouchMove: false
      },

      swiperInfoOption: {
        slidesPerView: 1,
        centeredSlides: true,
        direction: 'vertical',
        speed: 750,
        allowTouchMove: false,
        touchReleaseOnEdges: true,
        scrollbar: {
          el: '.index-marketplace__info-scrollbar',
          draggable: true
        },
        mousewheel: {
          eventsTarget: '.index-marketplace',
          releaseOnEdges: true
        },
        on: {
          slideChange: function() {
            setTimeout(() => {
              this.params.mousewheel.releaseOnEdges = false;
              this.params.touchReleaseOnEdges = false;
            }, 500);
          },
          reachBeginning: function() {
            setTimeout(() => {
              this.params.mousewheel.releaseOnEdges = true;
              this.params.touchReleaseOnEdges = true;
            }, 750);
          },
          reachEnd: function() {
            setTimeout(() => {
              this.params.mousewheel.releaseOnEdges = true;
              this.params.touchReleaseOnEdges = true;
            }, 750);
          }
        },
        keyboard: {
          enabled: true,
          pageUpDown: true
        },
        init: false
      },

      swiperFullscreenOption: {
        slidesPerView: 1,
        direction: 'vertical',
        speed: 750,
        touchReleaseOnEdges: true,
        mousewheel: {
          eventsTarget: '.index-marketplace',
          releaseOnEdges: true
        },
        on: {
          slideChange: function() {
            setTimeout(() => {
              this.params.mousewheel.releaseOnEdges = false;
              this.params.touchReleaseOnEdges = false;
            }, 500);
          },
          reachBeginning: function() {
            setTimeout(() => {
              this.params.mousewheel.releaseOnEdges = true;
              this.params.touchReleaseOnEdges = true;
            }, 750);
          },
          reachEnd: function() {
            setTimeout(() => {
              this.params.mousewheel.releaseOnEdges = true;
              this.params.touchReleaseOnEdges = true;
            }, 750);
          }
        },
        keyboard: {
          enabled: true,
          pageUpDown: true
        },
        init: false
      }
    }
  },

  mounted() {
    this.mySwiperInfo.controller.control = this.mySwiperFullscreen;
    this.mySwiperFullscreen.controller.control = [this.mySwiperInfo, this.mySwiperWebsite];

    this.mySwiperInfo.init(this.swiperInfoOption);
    this.mySwiperWebsite.init(this.swiperWebsiteOption);
    this.mySwiperFullscreen.init(this.swiperFullscreenOption);
  },

  beforeRouteLeave(to, from, next) {
    this.mySwiperWebsite.destroy();
    this.mySwiperInfo.destroy();
    next();
  }
}

</script>

<style lang="scss">
@import '@/assets/scss/elements/index/call-to-action';
@import '@/assets/scss/elements/index/marketplace';
</style>